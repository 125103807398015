<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Operation') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('thirdParty') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <b-card class="ll-card">
      <b-card-body>
        <b-row>
          <b-col lg="8">
        <b-row>
          <b-col lg="6">
            <b-form-group
              :label="$t('kuaidiCompany')"
              label-for=""
            >
              <v-select
                :options="kuaidiList"
                label="codeDescription"
                @input="selKuaidi"
                v-model="kuaidi"
                class="ll-select"
              ></v-select>
            </b-form-group>
          </b-col>
           <b-col lg="6">
            <el-row>
              <b-button
                variant="info"
                size="lg"
                v-b-modal.cancel-3rd
                @click="customerOrderNo=''"
                style="float:right"
                clearfix
              >
                <span class="mr-25 align-middle">Cancel 3rd Party Courier Number</span>
              </b-button>
            </el-row>
            <el-row>
              <b-button
                variant="info"
                size="lg"
                v-b-modal.order-tel
                @click="customerOrderNo=''"
                style="float:right"
                clearfix
                class="mt-1"
              >
                <span class="mr-25 align-middle">Revise Order TEL</span>
              </b-button>
            </el-row>
           </b-col>
        </b-row>
        <b-row class="ll-brow">
          <b-col lg="12">
            <div class="scanBox" :style="bg">
              <feather-icon
                :icon="icon"
                size="156"
              />
              <span>{{text}}</span>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <b-form-group
              :label="$t('originNum')"
              label-for=""
              class="ll-input"
            >
              <b-form-input size="ll"
                v-model="scaninfo.customerOrderNo"
                @keyup.enter="nextFocus($event)"
                ref="input1"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              :label="$t('thirdPartyNum')"
              label-for=""
               class="ll-input"
            >
              <b-form-input size="ll"
                v-model="scaninfo.kuaidiRefNum"
                ref="input"
                @keyup.enter="scanRes"
              />
            </b-form-group>
          </b-col>
        </b-row>
          </b-col>
          <b-col lg="4">
            <b-table :items="items" :fields="fields"
              :borderless='false' :hover="true"
              :sticky-header="false"
              class="ll-table1"
              :row-class-name="tableRowClassName"
              v-if="false"
            >
            </b-table>
            <el-table
              :row-style="{ height: '20px' }"
              :cell-style="{ fontSize: '14px', padding: '3px 0' }"
              :data="items"
              :row-class-name="tableRowClassName"
              empty-text="No matching records found"
            >
            <el-table-column
              prop="time"
              fixed="left"
              label="Create Time"
            >
            </el-table-column>
            <el-table-column
              prop="originNum"
              fixed="left"
              :label="$t('originNum')"
            >
            </el-table-column>
            <el-table-column
              prop="thirdPartyNum"
              fixed="left"
              :label="$t('thirdPartyNum')"
            >
            </el-table-column>
            <el-table-column
              prop="result"
              fixed="left"
              label="Result"
              width="80"
            >
            </el-table-column>
          </el-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    /** Revise Order TEL */
     <b-modal
      id="order-tel"
      centered
      :title="$t('Revise Order TEL')"
      size="md"
      hide-footer
      ref="order-tel-modal"
    >
       <b-form-group
          class="g-label"
          label=""
        >
          <div class="demo-inline-spacing">
            <b-form-radio
              name="some-radios"
              value="orderNumber"
              v-model="type"
            >
              Order Number
            </b-form-radio>
            <b-form-radio
              name="some-radios"
              value="kuaidi"
              v-model="type"
            >
              3rd Party Courier Number
            </b-form-radio>
          </div>
        </b-form-group>
        <b-form-group
          class="g-label"
          label=""
          label-for=""
        >
          <b-form-input
            id="number"
            v-model="number"
          />
        </b-form-group>
        <div class="ll-subBtn">
          <b-button
            variant="primary"
            @click="searchPhone"
            :disabled="!number"
          >
            {{ $t('Search') }}
          </b-button>
        </div>
         <b-form-group
          class="g-label"
          label="Original Consignee Phone Number"
          label-for="Original Consignee Phone Number"
        >
          <b-form-input
          :disabled="true"
          v-model="oldPhone"
          />
        </b-form-group>
         <b-form-group
          class="g-label"
          label="New Consignee Phone Number"
          label-for="New Consignee Phone Number"
        >
          <b-form-input
          v-model="newPhone"
          type="number"
          min="4"
          @blur="blurPhone"
          />
        </b-form-group>
        <div class="ll-subBtn">
          <b-button
            variant="info"
            @click="confirmPhone"
            :disabled="!newPhone || !oldPhone"
          >
            {{ $t('Confirm') }}
          </b-button>
        </div>
     </b-modal>
     /** Cancel 3rd Party Courier Number */
    <b-modal
      id="cancel-3rd"
      centered
      :title="$t('Cancel 3rd Party Courier Number')"
      size="sm"
      hide-footer
      ref="cancel-3rd-modal"
    >
    <validation-observer ref="rules">
      <b-form-group
        label=""
        label-for="kec-order"
      >
        <validation-provider
                  #default="{ errors }"
                  name="customerOrderNo"
                  rules="required"
                >
          <label class="ll-boldText">{{ $t('KE Order Number') }}</label>
          <b-form-input size="ll" id="kec-order" v-model="customerOrderNo" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
    <div class="ll-subBtn">
      <b-button
        variant="primary"
        @click="confirm"
      >
        {{ $t('Confirm') }}
      </b-button>
    </div>
    </b-modal>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem,
  BRow, BCol, BFormGroup, BFormInput,
  BCard, BCardBody, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    vSelect,
    BTable,
    ToastificationContent
  },
  data() {
    return {
      text: this.$t('beforeScan'),
      scaninfo: {
        customerOrderNo: null,
        kuaidiRefNum: null,
        company: null,
      },
      customerOrderNo:'',
      tkID: null,
      kuaidiRefNum: null,
      kuaidiList: [],
      kuaidi: null,
      bgColor: '#ff9f43',
      status: '',
      items: [],
      fields:[
        { key: 'time', label: 'Create Time' },
        { key: 'originNum', label: '原始单号' },
        { key: 'thirdPartyNum', label: '第三方快递单号' },
        { key: 'result', label: 'Result' }
      ],
      icon:'AlertCircleIcon',
      type:'orderNumber',
      number:'',
      oldPhone:'',
      newPhone:'',
    }
  },
  computed: {
    bg() {
      return {
        '--bgColor': this.bgColor
      }
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.queryKuaidi()
  },
  methods: {
    blurPhone(){
      let that = this
      console.info(that.newPhone,'0----')
      if (that.newPhone) {
        let phoneStr = that.newPhone.toString()
        let e = phoneStr.replace(/\+|\-/g,'')
        that.newPhone = e
        that.$forceUpdate()
        console.info(e,typeof(e))
      }
    },
    searchPhone(){
      if(!this.number){
        this.$toast({
            component: ToastificationContent,
            props: {
            title: 'Please enter the number.',
            icon: 'CheckIcon',
            variant: 'error',
            },
        })
        return false;
      }
      this.$http.get(`/kuaidi/searchPhone?number=${this.number}&type=${this.type}`).then(res=>{
        this.oldPhone = res.data.data
      })
    },
    confirmPhone(){
      if(!this.newPhone){
        this.$toast({
            component: ToastificationContent,
            props: {
            title: 'Please enter the  New Consignee Phone Number.',
            icon: 'CheckIcon',
            variant: 'error',
            },
        })
        return false;
      }
      if (this.newPhone.length < 4) {
        this.$toast({
            component: ToastificationContent,
            props: {
            title: 'New Consignee Phone Number 長度限制最小要有4位數字',
            icon: 'CheckIcon',
            variant: 'error',
            },
        })
        return false;
      }
        this.$http.get(`/kuaidi/resend?number=${this.number}&type=${this.type}&phone=${this.newPhone}`).then(res=>{
          if (res.data.code == 200) {
              this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: res.data.message,
                  icon: 'CheckIcon',
                  variant: 'success',
                  },
              })
              this.$refs['order-tel-modal'].hide()
          }
        })
    },
    scanRes() {
      if (this.scaninfo.company !== null) {
        this.$http.post('/ordersKuaidi/scan', this.scaninfo).then(res=>{
          // console.log(res.data)
          const info = res.data
          this.status = info.message
          switch (info.message) {
            case 'NOT_EXIST':
              this.text = this.$t('NumNotExist')
              this.bgColor = '#ea5455'
              this.icon = 'XCircleIcon'
              break
            case 'FAIL':
              this.text = this.$t('bindFail')
              this.bgColor = '#ea5455'
              this.icon = 'XCircleIcon'
              break
            case 'SUCCESS':
              this.text = this.$t('bindSuccess')
              this.bgColor = '#28c76f'
              this.icon = 'CheckCircleIcon'
              break
          }

          const obj = {}
          const fromDate = new Date()
          obj.time = fromDate.getFullYear() + "-" +
          (fromDate.getMonth()> 8 ? (fromDate.getMonth() + 1) : "0" + (fromDate.getMonth() + 1)) + "-"
          + (fromDate.getDate()> 9 ? (fromDate.getDate()) : "0" + (fromDate.getDate()))
          + ' ' + (fromDate.getHours()>9 ? (fromDate.getHours()) : '0'+(fromDate.getHours())) + ':'
          + (fromDate.getMinutes()>9 ? (fromDate.getMinutes()) : '0'+(fromDate.getMinutes()))
          + ':' + (fromDate.getSeconds()>9 ? (fromDate.getSeconds()) : '0'+(fromDate.getSeconds()))
          obj.originNum = this.scaninfo.customerOrderNo
          obj.thirdPartyNum = this.scaninfo.kuaidiRefNum
          obj.result = (info.message === 'SUCCESS' ? 'success' : 'failed')
          this.items.unshift(obj)

          this.scaninfo.customerOrderNo = ''
          this.scaninfo.kuaidiRefNum = ''
          this.$refs.input1.focus()
        })
      }
    },
    tableRowClassName({row, rowIndex}) {
      if (row.result !== 'success') {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    confirm(){
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.$http.get('/ordersKuaidi/cancel?customerOrderNo='+this.customerOrderNo).then(res => {
            console.info(res,'res')
            if (res.data.code == 200) {
              this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: res.data.message,
                  icon: 'CheckIcon',
                  variant: 'success',
                  },
              })
               this.$refs['cancel-3rd-modal'].hide()
            }
          })
        }
      })
    },
    queryKuaidi() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'Kuaidi100CompanyType'}}).then(res => {
        this.kuaidiList = res.data.data
      })
    },
    selKuaidi(d) {
      this.scaninfo.company = d ? d.codeName : null
      this.kuaidi = d ? d.codeDescription : null
    },
    nextFocus() {
      this.$refs.input.focus()
    },
  }
}
</script>

<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
}
.scanBox{
  width: 100%;
  height: 50vh;
  background: var(--bgColor);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 56px;
}
.ll-brow{
  margin-top: 20px;
  margin-bottom: 20px;
}
.ll-card {
  font-size: 30px;
  font-weight: bold;
}
.ll-input .form-control-ll{
  font-size: 40px;
  font-weight: bold;
  height: 7rem;
  border: 5px solid;
}
.ll-select svg{
  width: 40px;
  height: 40px;
}
[dir] .btn-outline-danger{
  border: 4px solid #ea5455 !important;
}
.btn-lg, .btn-group-lg > .btn{
  font-size: 2rem !important;
  line-height: 2rem !important;
}
</style>
<style>
[dir=ltr] .el-table .cell{
  font-size: 1rem;
}
[dir=ltr] .el-table__fixed{
  font-size: 1rem;
}
.el-table .warning-row {
  background:#ea5455;
  color: #fff;
}
.el-table .hover-row{
  color: #606266 !important;
}
.el-table .success-row {
  background: #28c76f;
  color: #fff;
}
.ll-table1 td {
  font-size: 1rem !important;
  font-weight: unset !important;
}
[dir] .form-control:focus{
  border-color: #ed6d01;
}
[dir=ltr] .ll-select .vs__dropdown-toggle {
  border: 5px solid;
}

</style>